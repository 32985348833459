<template>
  <div class="NewsAndMediaPage">
    <hero-cmp class="colorful-bg">
      <template #type>
        <slot name="news-type" />
      </template>
      <template #title>
        <slot name="news-hero-title" />
      </template>
    </hero-cmp>
    <section
      v-if="highlight_article && highlight_article.href"
      :class="[{ 'pb-l': latest_articles && latest_articles.length }]"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <card-cmp
              :data-href="highlight_article.href"
              class="highlight highlight-article"
            >
              <template #seo-link>
                <slot name="news-highlight-seo-link" />
              </template>
              <template #image>
                <slot name="news-highlight-image" />
              </template>
              <template
                v-if="
                  highlight_article.topics && highlight_article.topics.length
                "
                #tags
              >
                <slot name="news-highlight-tags" />
              </template>
              <template v-if="highlight_article.category" #category>
                <slot name="news-highlight-category" />
              </template>
              <template #title>
                <slot name="news-highlight-title" />
              </template>
              <template #date>
                <time :datetime="highlight_article.publication_start_iso">
                  <beautiful-date
                    :data-datetime="highlight_article.publication_start_iso"
                    :data-locale="currentLang.language_code"
                    :data-format="$store.state.config.date_format"
                  >
                    {{ highlight_article.publication_start }}
                  </beautiful-date>
                </time>
              </template>
              <template #cta>
                <cta-button-cmp-extended class="btn-text" data-tag="button">
                  {{ news_and_media_read_more_label }}
                  <template #after-icon>
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </card-cmp>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="latest_articles && latest_articles.length"
      class="bg-color-blue-10 py-l"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <title-separator-cmp class="latest latest-separator">
              <template #title>
                <h3>{{ news_and_media_latest_articles_label }}</h3>
              </template>
            </title-separator-cmp>
          </div>
          <div
            v-for="(latest, i) in latest_articles"
            :key="`latest-${latest.slug}`"
            :class="[
              'col-12',
              'col-md-5',
              'latest-article',
              { first: i === 0 },
            ]"
          >
            <card-cmp :data-href="latest.href" class="latest">
              <template #seo-link>
                <slot :name="`news-latest-seo-link-${i + 1}`" />
              </template>
              <template #image>
                <image-cmp
                  width="1062"
                  height="596"
                  :data-desktop-image="latest.list_image"
                  :data-image-alt="latest.list_image_alt"
                />
                <slot :name="`news-latest-image-${i + 1}`" />
              </template>
              <template v-if="latest.topics && latest.topics.length" #tags>
                <slot :name="`news-latest-tags-${i + 1}`" />
              </template>
              <template v-if="latest.category" #category>
                <slot :name="`news-latest-category-${i + 1}`" />
              </template>
              <template #title>
                <slot :name="`news-latest-title-${i + 1}`" />
              </template>
              <template #date>
                <time :datetime="latest.publication_start_iso">
                  <beautiful-date
                    :data-datetime="latest.publication_start_iso"
                    :data-locale="currentLang.language_code"
                    :data-format="$store.state.config.date_format"
                  >
                    {{ latest.publication_start }}
                  </beautiful-date>
                </time>
              </template>
              <template #cta>
                <cta-button-cmp-extended class="btn-text" data-tag="button">
                  {{ news_and_media_read_more_label }}
                  <template #after-icon>
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                </cta-button-cmp-extended>
              </template>
            </card-cmp>
          </div>
        </div>
      </div>
    </section>
    <section class="py-l">
      <div class="container">
        <div class="row justify-content-center">
          <div v-if="categories_all" class="col-12 col-md-10">
            <tabs-cmp
              :data-active-tab-id="categories_all.news_and_media_type"
              :data-tabs="tabs"
              :data-show-headers="computedShowHeaders"
            >
              <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data" />
              </template>
            </tabs-cmp>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { currentLang } from '@/helpers/utils';

export default {
  name: 'NewsAndMediaPage',
  computed: {
    ...mapState('config', ['date_format']),
    ...mapState('newsAndMedia', [
      'categories',
      'categories_all',
      'highlight_article',
      'hub_hero_overtitle',
      'hub_hero_title',
      'latest_articles',
      'news_and_media_latest_articles_label',
      'news_and_media_read_more_label',
    ]),
    currentLang() {
      return currentLang(this.$store.state.config.ordered_languages);
    },

    computedShowHeaders() {
      return (
        this.tabs.length >= 2 &&
        !!this.categories.find((i) => i.visible_in_hub_page)
      );
    },
    tabs() {
      let tabs = [];
      if (
        this.categories &&
        this.categories.length > 0 &&
        this.categories_all
      ) {
        tabs = this.categories.reduce((acc, curr) => {
          return curr.visible_in_hub_page
            ? [
                ...acc,
                {
                  id: curr.news_and_media_type,
                  label: curr.title,
                },
              ]
            : acc;
        }, []);
        tabs.unshift({
          id: this.categories_all.news_and_media_type,
          label: this.categories_all.title,
        });
      }
      return tabs;
    },
  },
};
</script>

<style lang="scss" scoped>
.NewsAndMediaPage {
  .highlight-article {
    margin-top: 40px;

    @include media-breakpoint-up(md) {
      margin-top: -40px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -80px;
    }
  }

  .latest-separator {
    padding-bottom: 40px;

    @include media-breakpoint-up(md) {
      padding-bottom: 32px;
    }
  }

  .latest-article {
    @include media-breakpoint-down(md) {
      &:not(.first) {
        padding-top: 40px;
      }
    }
  }

  .TabsCmp {
    ::v-deep .tab-content {
      .row.cards-wrapper {
        row-gap: 40px;

        @include media-breakpoint-up(md) {
          row-gap: 64px;
        }
      }
    }
  }
}
</style>
