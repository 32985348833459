<template>
  <div class="SecondLevelCategoryPage">
    <hero-cmp
      :class="[
        {
          'colorful-bg-with-img': lgAndUp && conf.hero_side_img,
          'colorful-bg': !lgAndUp || !conf.hero_side_img,
        },
        conf.category_color,
      ]"
    >
      <template v-if="lgAndUp" #image>
        <slot name="product-image" />
      </template>
      <template #type>
        <slot name="product-type" />
      </template>
      <template #title>
        <slot name="product-hero-title" />
      </template>
    </hero-cmp>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 py-xs">
          <slot name="product-breadcrumbs" />
          <title-text-cmp class="pt-r pb-l">
            <template #title>
              <slot name="product-title" />
            </template>
            <template #text>
              <slot name="product-description" />
            </template>
          </title-text-cmp>
        </div>
      </div>
    </div>
    <focus-items-cmp
      v-if="conf.category_properties && conf.category_properties.length"
      class="with-icons"
      :data-box-color="conf.category_color"
    >
      <template #icons>
        <slot name="category-properties" />
      </template>
    </focus-items-cmp>
    <div
      :class="[
        'container pb-l',
        {
          'pt-xl': conf.category_properties && conf.category_properties.length,
        },
      ]"
    >
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <tabs-cmp
            v-if="hasTabs"
            :data-active-tab-id.sync="currentTab"
            :data-tabs="tabs"
            :data-no-content="true"
          ></tabs-cmp>
          <!---->
          <div
            v-if="
              (weights.length && weights.length > 1) ||
              isSelectCountryVisibleInSecondLevel /*&&
        atLeastOneProductHasAtLeastOneCountry*/
            "
            :class="[
              {
                'pt-l':
                  (conf.category_properties &&
                    conf.category_properties.length) ||
                  hasTabs,
              },
            ]"
          >
            <p class="a-support text-color-grey-800 pb-xs">
              {{ filter_by_label }}
            </p>
            <div class="d-flex" style="gap: 50px">
              <dropdown-cmp-extended
                v-if="weights.length && weights.length > 1"
                data-tag="button"
                :data-trigger-theme-classes="[
                  { selected: selectedWeights.length > 0 },
                ]"
                :data-menu-theme-classes="[
                  'shadow-soft',
                  { scrollable: weights.length > 8 },
                ]"
              >
                {{ net_weight_label }}
                <template v-if="selectedWeights.length > 0">
                  ({{ selectedWeights.length }})
                </template>
                <template #after-icon>
                  <svg class="icon-caret-down">
                    <use href="#icon-caret-down" />
                  </svg>
                </template>

                <template #dropdown-menu>
                  <div class="scroll-container">
                    <input-checkbox-cmp-extended
                      v-model.trim="selectedWeights"
                      :data-override-boolean-behaviour="true"
                      data-name="checkbox"
                      data-aria-label="Checkbox"
                      class="custom direction-column"
                      :data-checkbox-list="
                        weights.map((w) => ({
                          value: w.value,
                          label: customDecimalSeparator(
                            w.label,
                            $store.state.config.decimal_separator
                          ),
                        }))
                      "
                    />
                  </div>
                </template>
              </dropdown-cmp-extended>
              <dropdown-cmp-extended
                v-if="
                  isSelectCountryVisibleInSecondLevel /*&&
                  atLeastOneProductHasAtLeastOneCountry*/
                "
                data-tag="button"
                :data-trigger-theme-classes="[
                  { selected: selectedCountries.length > 0 },
                ]"
                :data-menu-theme-classes="[
                  'shadow-soft',
                  { scrollable: countriesList.length > 8 },
                ]"
              >
                {{ countriesFilterLabel }}
                <template v-if="selectedCountries.length > 0">
                  ({{ selectedCountries.length }})
                </template>
                <template #after-icon>
                  <svg class="icon-caret-down">
                    <use href="#icon-caret-down" />
                  </svg>
                </template>
                <template #dropdown-menu>
                  <div class="scroll-container">
                    <input-checkbox-cmp-extended
                      v-model.trim="selectedCountries"
                      :data-override-boolean-behaviour="true"
                      data-name="checkbox"
                      data-aria-label="Checkbox"
                      class="custom direction-column"
                      :data-checkbox-list="
                        countriesList.map((w) => ({
                          value: w.value,
                          label: w.label,
                        }))
                      "
                    />
                  </div>
                </template>
              </dropdown-cmp-extended>
            </div>
          </div>
          <div class="pt-l">
            <div class="row mt-nr">
              <div
                v-for="(product, index) of filteredProducts"
                :key="`product_${product.slug}_${index}`"
                class="col-md-6 col-lg-4 pt-r"
              >
                <card-product-cmp
                  class="product animationForTransparentsImgs"
                  :data-href="product.href"
                  :data-quantity="product.quantity_parsed"
                  :data-measure-unit-value="product.measure_unit_value"
                  :data-measure-unit="product.measure_unit"
                >
                  <template #title>
                    <h3>{{ product.title }}</h3>
                  </template>
                  <template #image>
                    <img :src="product.image" :alt="product.image_alt" />
                  </template>
                </card-product-cmp>
              </div>
              <div v-if="showEmptyMessage" class="empty-message">
                {{ noProductsLabel }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <carousel-certifications-cmp
      v-if="conf.info_boxes && conf.info_boxes.length"
      class="pb-l"
    >
      <template #carousel>
        <slot name="product-certifications" />
      </template>
    </carousel-certifications-cmp>
  </div>
</template>

<script>
import { customDecimalSeparator } from '@/helpers/utils';
import { castPropToType } from '@/helpers/cms-support';
import { mdAndUp, lgAndUp } from '@/helpers/breakpoints';
import { mapState } from 'vuex';

export default {
  name: 'SecondLevelCategoryPage',
  props: {
    dataConfig: { type: String, required: true },
    subcategory: { type: String, default: '' },
  },
  setup() {
    return { mdAndUp, lgAndUp, castPropToType, customDecimalSeparator };
  },
  data() {
    return {
      selectedWeights: [],
      selectedCountries: [],
    };
  },

  computed: {
    ...mapState('products', [
      'filter_by_label',
      'net_weight_label',
      'all_label',
      'noProductsLabel',
      'countriesFilterLabel',
      'isSelectCountryVisibleInSecondLevel',
    ]),
    conf() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    atLeastOneProductHasAtLeastOneCountry() {
      return this.mergeArrays(
        this.conf.products.map((product) => {
          return product.available_countries;
        })
      ).length;
    },
    countriesList() {
      return this.conf.countries ? this.conf.countries : [];
    },
    currentTab: {
      get() {
        if (this.subcategory) {
          return this.subcategory;
        }
        return '__ALL__';
      },
      set(slug) {
        if (slug === '__ALL__') {
          this.$router.push({ name: 'index' });
        } else {
          this.$router.push({ name: 'index', params: { subcategory: slug } });
        }
      },
    },
    tabs() {
      return [
        {
          id: '__ALL__',
          label: this.all_label,
        },
        ...this.conf.sub_categories.map((subcat) => {
          return {
            id: subcat.slug,
            label: subcat.list_title,
          };
        }),
      ];
    },
    hasTabs() {
      try {
        return this.tabs.length > 2;
      } catch (e) {
        return true;
      }
    },
    products() {
      return this.mergeArrays(
        this.conf.products.map((product) => {
          if (!product.product_weights.length) {
            return [
              {
                href: product.href,
                slug: product.slug,
                category: product.category_slug,
                title: product.list_description,
                image: product.list_image,
                image_alt: product.list_image_alt,
                weightId: null,
                quantity_parsed: null,
                measure_unit_value: null,
                measure_unit: null,
                available_countries: product.available_countries,
              },
            ];
          }
          return product.product_weights.map((weight) => {
            return {
              href: product.href,
              slug: product.slug,
              category: product.category_slug,
              title: product.list_description,
              image: product.list_image,
              image_alt: product.list_image_alt,
              weightId: `${weight.id}`,
              quantity_parsed: weight.quantity_parsed,
              measure_unit_value: weight.measure_unit_value,
              measure_unit: weight.measure_unit,
              available_countries: product.available_countries,
            };
          });
        })
      );
    },
    // #fe-point-filteredProducts
    filteredProducts() {
      return (
        this.products
          .filter((product) => {
            if (this.subcategory) {
              return this.subcategory === product.category;
            }
            return true;
          })
          /* questo filtro è solo client può essere ignorato in fase di render della pagina, non è persistente. */
          .filter((product) => {
            if (this.selectedWeights.length) {
              return this.selectedWeights.includes(product.weightId);
            }
            return true;
          })
          .filter((product) => {
            return this.selectedCountries.length
              ? product.available_countries
                  .map((countryObject) => countryObject.value)
                  .some((countrySlug) =>
                    this.selectedCountries.includes(countrySlug)
                  )
              : true;
          })
      );
    },
    weights() {
      return this.weightsWithItems.map((weight) => {
        return {
          value: `${weight.id}`,
          label: `${weight.quantity_parsed} ${weight.measure_unit}`,
        };
      });
    },
    weightsWithItems() {
      if (this.conf.products_weights) {
        return this.conf.products_weights.filter((w) => {
          return !!this.products.find((p) => +p.weightId === +w.id);
        });
      } else {
        return [];
      }
    },
    filteredWeights() {
      if (this.conf.products_weights) {
        return this.conf.products_weights.filter((w) => {
          return !!this.filteredProducts.find((p) => +p.weightId === +w.id);
        });
      } else {
        return [];
      }
    },
    showEmptyMessage() {
      return this.filteredProducts.length === 0;
    },
  },
  methods: {
    mergeArrays(items) {
      return [].concat.apply([], items);
    },
  },
};
</script>
