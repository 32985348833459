<template>
  <div class="RecipeDetailPage">
    <hero-cmp class="recipes">
      <template #type>
        <slot name="recipe-type" />
      </template>
      <template #title>
        <slot name="recipe-hero-title" />
      </template>
    </hero-cmp>
    <div class="first-section-wrapper">
      <div class="container pb-l">
        <div v-if="conf.breadcrumbs" class="row justify-content-center">
          <div class="col-auto">
            <slot name="recipe-breadcrumbs" />
          </div>
        </div>
        <div v-if="conf.image" class="row justify-content-center">
          <div class="col-12 col-md-8">
            <slot name="recipe-image" />
          </div>
        </div>
        <div
          v-if="
            conf.people_value &&
            conf.food_cost_value &&
            !isNaN(conf.food_cost_value)
          "
          class="row justify-content-center"
        >
          <div class="col-12 col-md-8">
            <info-dropdown-cmp
              :data-difficulty="conf.food_cost_value"
              :class="['mt-9', { twoCols: !hasCookingTimeData }]"
            >
              <template #dropdown>
                <template v-if="peopleRadioList.length === 1">
                  <div class="n-heading-2 mt-5">
                    {{ peopleRadioList[0].label }}
                  </div>
                </template>
                <template v-else>
                  <dropdown-cmp-extended
                    data-tag="button"
                    :data-trigger-theme-classes="['btn-big']"
                    :data-menu-theme-classes="['shadow-soft']"
                  >
                    {{ selectedPeopleValueLabel }}
                    <template #after-icon>
                      <svg class="icon-caret-down">
                        <use href="#icon-caret-down" />
                      </svg>
                    </template>
                    <template #dropdown-menu>
                      <input-radio-cmp-extended
                        v-model.trim="peopleValue"
                        data-name="radio"
                        data-aria-label="Radio"
                        class="custom direction-column"
                        :data-radio-list="peopleRadioList"
                      />
                    </template>
                  </dropdown-cmp-extended>
                </template>
              </template>
              <template #first-box-title>
                {{ conf.people_label }}
              </template>
              <template #first-box-copy>
                {{ conf.people_description }}
              </template>
              <template v-if="hasCookingTimeData" #second-box-number>
                {{ conf.cooking_time_value }}'
              </template>
              <template v-if="hasCookingTimeData" #second-box-title>
                {{ conf.cooking_time_label }}
              </template>
              <template v-if="hasCookingTimeData" #second-box-copy>
                {{ conf.cooking_time_description }}
              </template>
              <template #third-box-title>
                {{ conf.food_cost_label }}
              </template>
              <template #third-box-copy>
                {{ conf.food_cost_description }}
              </template>
            </info-dropdown-cmp>
          </div>
        </div>

        <div v-if="conf.ingredients" class="row justify-content-center">
          <div class="col-12 col-md-8">
            <ingredients-steps-list-cmp class="mt-9">
              <template #ingredients>
                <template v-for="(ingredientGroup, n) in groupedIngredients">
                  <p
                    v-if="ingredientGroup.group_title"
                    :key="'cookingIngredientsListHeading-' + n"
                    class="ingredientsGroup"
                  >
                    {{ getValueForIngredient(ingredientGroup.group_title) }}
                    {{ ingredientGroup.group_title.value_measure_unit }}
                    <template
                      v-if="
                        +(
                          (ingredientGroup.group_title.value / 4) *
                          peopleValue
                        ) === 1 && ingredientGroup.group_title.singular
                      "
                    >
                      {{ ingredientGroup.group_title.singular }}
                    </template>
                    <template v-else>
                      {{ ingredientGroup.group_title.plural }}
                    </template>
                  </p>
                  <ul
                    :key="'cookingIngredientsList-' + n"
                    class="ingredientsList"
                  >
                    <li
                      v-for="(ingredient, index) in ingredientGroup.group_items"
                      :key="`cookingingredient-${n}-${index}`"
                    >
                      {{ getValueForIngredient(ingredient) }}
                      {{ ingredient.value_measure_unit }}
                      <template
                        v-if="
                          +((ingredient.value / 4) * peopleValue) === 1 &&
                          ingredient.singular
                        "
                      >
                        {{ ingredient.singular }}
                      </template>
                      <template v-else>
                        {{ ingredient.plural }}
                      </template>
                    </li>
                  </ul>
                </template>
              </template>
              <template #title-separator>
                <title-separator-cmp>
                  <template #title>
                    <h3>{{ detail_ingredients_label }}</h3>
                  </template>
                  <template v-if="conf.people_value_full_info" #text>
                    {{ conf.people_value_full_info }}
                    {{ detail_servings_label }}
                  </template>
                </title-separator-cmp>
              </template>
              <template v-if="conf.cooking_product" #card-product>
                <slot name="recipe-cooking-product" />
              </template>
            </ingredients-steps-list-cmp>
          </div>
        </div>
        <div v-if="conf.cooking_steps" class="row justify-content-center">
          <div class="col-12 col-md-8">
            <ingredients-steps-list-cmp class="mt-9">
              <template #procedure>
                <ol>
                  <li
                    v-for="(step, index) in conf.cooking_steps"
                    :key="'cookingstep-' + index"
                  >
                    <span v-if="!conf.is_simplified_recipe_template">
                      {{ index + 1 }}.
                    </span>
                    {{ step.description }}
                  </li>
                </ol>
              </template>
              <template #title-separator>
                <title-separator-cmp>
                  <template #title>
                    <h3>{{ detail_preparation_label }}</h3>
                  </template>
                </title-separator-cmp>
              </template>
            </ingredients-steps-list-cmp>
          </div>
        </div>
        <div v-if="conf.chef_note" class="row justify-content-center">
          <div class="col-12 col-md-10">
            <slot name="recipe-chef-note" />
          </div>
        </div>
      </div>
      <template v-if="conf.video_link_code">
        <media-player-cmp
          class="highlighted"
          :data-youtube-video-id="conf.video_link_code"
          :data-background-image-src="
            conf.video_poster ? conf.video_poster : null
          "
        >
          <p>
            {{ conf.video_description }}
          </p>
        </media-player-cmp>
      </template>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <recipes-footer-cmp class="mt-7">
              <template v-if="conf.categories" #tags>
                <a
                  v-for="category in conf.categories"
                  :key="category.slug"
                  :href="category.href"
                >
                  <tag-cmp>
                    {{ category.name }}
                  </tag-cmp>
                </a>
              </template>

              <template v-if="detail_social_share_label" #title-separator>
                <title-separator-cmp class="highlighted">
                  <template #title>
                    <div>{{ detail_social_share_label }}</div>
                  </template>
                  <template #social-share>
                    <social-share-cmp>
                      <social-share-item
                        v-for="social in socials"
                        :key="social.slug"
                        :data-social="social.social"
                        :data-is-share="social.is_share"
                        :data-share-url="social.path"
                        :data-href="social.link"
                        :data-target="social.target"
                        :data-rel="social.rel"
                        :data-title-share="social.title_share"
                        :data-subject-mail="social.subject_email"
                        :data-content-mail="social.content_email"
                        :data-content-whatsapp="social.content_whatsapp"
                        :data-share-smc-enabled="social.shareSmcEnabled"
                        :data-share-smc-click-forced="
                          social.shareSmcClickForced
                        "
                        :data-share-smc-click-event="
                          social.share_smc_click_event
                        "
                        :data-share-smc-product-code="
                          social.share_smc_product_code
                        "
                        :data-share-smc-highlighted-content="
                          social.share_smc_highlighted_content
                        "
                        @clicked="trackOnGa4(social)"
                      ></social-share-item>
                    </social-share-cmp>
                  </template>
                </title-separator-cmp>
              </template>
            </recipes-footer-cmp>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="conf.related_recipes"
      class="second-section-wrapper bg-color-blue-10 mt-9"
    >
      <div class="container pb-l pt-l">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <title-separator-cmp v-if="detail_related_recipes_label">
              <template #title>
                <div>{{ detail_related_recipes_label }}</div>
              </template>
              <template v-if="detail_related_recipes_url" #cta>
                <cta-button-cmp-extended
                  class="btn-text"
                  :data-url="detail_related_recipes_url"
                  :data-aria-label="detail_related_recipes_cta_label"
                >
                  {{ detail_related_recipes_cta_label }}
                  <template #after-icon>
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                  <template #seo-link>
                    <slot name="recipe-related-seo-link" />
                  </template>
                </cta-button-cmp-extended>
              </template>
            </title-separator-cmp>
          </div>
          <div class="col-12 col-md-10">
            <div class="row justify-content-center">
              <slot name="recipe-related-cards" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debug } from '@/helpers/utils';
import { castPropToType } from '@/helpers/cms-support';
import { mapState } from 'vuex';
import { customDecimalSeparator } from '@/helpers/utils';

export default {
  name: 'RecipeDetailPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType, customDecimalSeparator };
  },
  data() {
    return {
      peopleValue: '4',
    };
  },
  computed: {
    groupedIngredients() {
      if (!this.conf.ingredients) {
        return [];
      } else {
        return this.conf.ingredients.reduce((prev, curr) => {
          if (curr.is_group_title) {
            prev = [...prev, { group_title: curr, group_items: [] }];
          } else {
            if (!prev.length) {
              //primo giro e primo elemento non è titolo
              prev = [{ group_title: null, group_items: [curr] }];
            } else {
              const lastItem = prev[prev.length - 1];
              lastItem.group_items = [...lastItem.group_items, curr];
            }
          }
          return prev;
        }, []);
      }
    },
    selectedPeopleValueLabel() {
      // console.log(this.peopleRadioList, this.peopleValue);
      return this.peopleRadioList.find((i) => +i.value === +this.peopleValue)
        .label;
    },
    socials() {
      // eslint-disable-next-line
      //debug && console.error('TO IMPLEMENT SOCIAL PLUGINS');
      // eslint-disable-next-line
      //debug && console.error('TO IMPLEMENT WHATSAPP LOGIC');
      return [
        // {
        //   slug: 'linkedin',
        //   social: 'linkedin',
        //   is_share: true,
        //   path: window.location.href,
        //   link: null,
        //   target: null,
        //   rel: null,
        //   title_share: '',
        //   subject_email: '',
        //   content_email: '',
        //   content_whatsapp: '',
        //   shareSmcEnabled: null,
        //   shareSmcClickForced: null,
        //   share_smc_click_event: null,
        //   share_smc_product_code: null,
        //   share_smc_highlighted_content: null,
        // },
        {
          slug: 'whatsapp',
          social: 'whatsapp',
          is_share: true,
          path: window.location.href,
          link: null,
          target: null,
          rel: null,
          title_share: '',
          subject_email: '',
          content_email: '',
          content_whatsapp: window.location.href,
          shareSmcEnabled: false,
          shareSmcClickForced: false,
          share_smc_click_event: '',
          share_smc_product_code: '',
          share_smc_highlighted_content: '',
        },
        {
          slug: 'email',
          social: 'email',
          is_share: true,
          path: window.location.href,
          link: null,
          target: null,
          rel: null,
          title_share: '',
          subject_email: `${this.detail_hero_label} - ${this.conf.title}`,
          content_email: `${this.detail_hero_label} - ${this.conf.title}`,
          content_whatsapp: '',
          shareSmcEnabled: false,
          shareSmcClickForced: false,
          share_smc_click_event: '',
          share_smc_product_code: '',
          share_smc_highlighted_content: '',
        },
        // {
        //   slug: 'instagram',
        //   social: 'instagram',
        //   is_share: true,
        //   path: window.location.href,
        //   link: null,
        //   target: null,
        //   rel: null,
        //   title_share: '',
        //   subject_email: '',
        //   content_email: '',
        //   content_whatsapp: '',
        //   shareSmcEnabled: null,
        //   shareSmcClickForced: null,
        //   share_smc_click_event: null,
        //   share_smc_product_code: null,
        //   share_smc_highlighted_content: null,
        // },
        {
          slug: 'twitter',
          social: 'twitter',
          is_share: true,
          path: window.location.href,
          link: null,
          target: null,
          rel: null,
          title_share: '',
          subject_email: '',
          content_email: '',
          shareSmcEnabled: false,
          shareSmcClickForced: false,
          share_smc_click_event: '',
          share_smc_product_code: '',
          share_smc_highlighted_content: '',
        },
      ];
    },
    ...mapState('recipes', [
      'detail_hero_label',
      'detail_ingredients_label',
      'detail_servings_label',
      'detail_preparation_label',
      'detail_social_share_label',
      'detail_related_recipes_label',
      'detail_related_recipes_cta_label',
      'detail_chef_note_label',
      'detail_related_recipes_url',
    ]),
    conf() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    peopleRadioList() {
      let values = [];
      if (this.conf.people_dropdown_has_multiple_value) {
        values = [2, 4, 6, 10, 20, 50];
      } else {
        values = [this.conf.people_value];
      }
      // this.conf.people_dropdown_has_multiple_value
      //https://trello.com/c/QXuJNdV0
      // for (let i = 1; i <= this.conf.people_value; i++) {
      //   radiolist.push({
      //     value: i,
      //     label: i,
      //   });
      // }
      return values.map((v) => {
        if (
          this.conf.people_dropdown_text_override.length &&
          !this.conf.people_dropdown_has_multiple_value
        ) {
          return {
            value: v,
            label: this.conf.people_dropdown_text_override,
          };
        } else {
          return {
            value: v,
            label: v,
          };
        }
      });
    },
    hasCookingTimeData() {
      return this.conf.cooking_time_value
        ? this.conf.cooking_time_value > 0
          ? true
          : false
        : false;
    },
  },
  mounted() {
    // eslint-disable-next-line
    debug && console.log(this.conf);
    this.peopleValue = this.conf.people_value;
    // this.$store.dispatch('recipesConfig/setConfig', this.conf);
  },
  methods: {
    trackOnGa4(social) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'social_share',
        'data-name-product': this.conf.title,
        'data-name-method': social.social,
        'data-content-type': 'recipe',
      });
    },
    getValueForIngredient(ingredient) {
      return ingredient.value !== ''
        ? customDecimalSeparator(
            '' + (ingredient.value / 4) * this.peopleValue,
            this.$store.state.config.decimal_separator
          )
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.RecipeDetailPage {
  .ingredientsGroup {
    margin: 0 0 5px;
    font-weight: bold;
  }

  .ingredientsList {
    & + .ingredientsGroup {
      margin: 20px 0 5px;
    }
  }
}
</style>
