<template>
  <div class="NewsAndMediaDetailPage">
    <hero-cmp :class="['article', { smallCover: has_small_cover }]">
      <template #image>
        <slot name="news-image" />
      </template>
      <template #breadcrumbs>
        <slot name="news-breadcrumb" />
      </template>
      <template #type>
        <slot name="news-type" />
      </template>
      <template #title>
        <slot name="news-hero-title" />
      </template>
      <template #date>
        <time :datetime="config.publication_start_iso">
          <beautiful-date
            :data-datetime="config.publication_start_iso"
            :data-locale="currentLang.language_code"
            :data-format="$store.state.config.date_format"
          >
            {{ config.publication_start }}
          </beautiful-date>
        </time>
      </template>
      <template #social-share>
        <social-share-cmp>
          <social-share-item
            v-for="social in socials"
            :key="social.slug"
            :data-social="social.social"
            :data-is-share="social.is_share"
            :data-share-url="social.path"
            :data-href="social.link"
            :data-target="social.target"
            :data-rel="social.rel"
            :data-title-share="social.title_share"
            :data-subject-mail="social.subject_email"
            :data-content-mail="social.content_email"
            :data-content-whatsapp="social.content_whatsapp"
            :data-share-smc-enabled="social.shareSmcEnabled"
            :data-share-smc-click-forced="social.shareSmcClickForced"
            :data-share-smc-click-event="social.share_smc_click_event"
            :data-share-smc-product-code="social.share_smc_product_code"
            :data-share-smc-highlighted-content="
              social.share_smc_highlighted_content
            "
            @clicked="trackOnGa4(social)"
          />
        </social-share-cmp>
      </template>
    </hero-cmp>
    <section v-if="hasEventSection" class="event-info-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <event-info-cmp>
              <template #when>
                <dl>
                  <dt>
                    <h4>{{ detail_when_event_label }}</h4>
                  </dt>
                  <dd>
                    <svg>
                      <use href="#icon-calendar" />
                    </svg>
                    <span>{{ config.start_date }}</span>
                  </dd>
                  <dd>
                    <svg>
                      <use href="#icon-clock" />
                    </svg>
                    <span>{{ eventTimespan }}</span>
                  </dd>
                </dl>
              </template>
              <template #where>
                <dl>
                  <dt>
                    <h4>{{ detail_where_event_label }}</h4>
                  </dt>
                  <dd>
                    <svg>
                      <use href="#icon-pinmap" />
                    </svg>
                    <span>{{ config.location }}</span>
                  </dd>
                </dl>
              </template>
              <template #cta>
                <cta-button-cmp-extended
                  class="btn-text"
                  :data-url="config.register_link.link"
                >
                  {{ config.register_link_label }}
                  <template #after-icon>
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                  <template #seo-link>
                    <slot name="news-event-seo-link" />
                  </template>
                </cta-button-cmp-extended>
              </template>
            </event-info-cmp>
          </div>
        </div>
      </div>
    </section>
    <text-with-cmp
      v-if="config.description"
      class="pb-l description-section-class-identify"
    >
      <template #text>
        <slot name="news-description" />
      </template>
    </text-with-cmp>
    <text-with-cmp
      v-if="hasMediaPlayerSection"
      class="pb-l media-player-section-class-identify"
    >
      <template v-if="config.video_description" #text>
        <slot name="news-video-description" />
      </template>
      <template v-if="config.video_caption || config.video_link_code" #video>
        <media-player-cmp
          :data-youtube-video-id="config.video_link_code"
          :data-background-image-src="
            config.video_poster ? config.video_poster : null
          "
        >
          <div class="wysiwyg" v-html="config.video_caption" />
        </media-player-cmp>
      </template>
    </text-with-cmp>
    <text-with-cmp
      v-if="hasCarouselSection"
      class="pb-l carousel-section-class-identify"
    >
      <template v-if="config.image_description" #text>
        <slot name="news-image-description" />
      </template>
      <template v-if="config.slides && config.slides.length" #images>
        <carousel-images-cmp :data-disabled-from="computedDisabled">
          <template #carousel>
            <div
              v-for="(item, i) in config.slides"
              :key="`image-slide-${i}-${_uid}`"
              class="swiper-slide"
            >
              <main-image-cmp>
                <template #zoom-in>
                  <svg
                    @mouseover="isHoveringZoomIcon = true"
                    @mouseleave="isHoveringZoomIcon = false"
                  >
                    <use
                      :href="`${
                        isHoveringZoomIcon
                          ? '#icon-zoom-in-hover'
                          : '#icon-zoom-in'
                      }`"
                    />
                  </svg>
                </template>
                <template #default>
                  <slot :name="`news-slide-image-${i + 1}`" />
                </template>
                <template #figcaption>
                  <slot :name="`news-slide-figcaption-${i + 1}`" />
                </template>
              </main-image-cmp>
            </div>
          </template>
          <template #carousel-lightbox>
            <div
              v-for="(item, i) in config.slides"
              :key="`lightbox-slide-${i}-${_uid}`"
              class="swiper-slide"
            >
              <main-image-cmp>
                <template #default>
                  <slot :name="`news-lightbox-image-${i + 1}`" />
                </template>
              </main-image-cmp>
            </div>
          </template>
        </carousel-images-cmp>
      </template>
    </text-with-cmp>
    <text-with-cmp
      v-if="hasQuoteSection"
      class="pb-l quote-section-class-identify"
    >
      <template v-if="config.quote_text" #text>
        <slot name="news-quote" />
      </template>
      <template
        v-if="
          config.quote_image || config.excerpt || config.author || config.cite
        "
        #quote
      >
        <quote-cmp>
          <template v-if="config.quote_image" #photo>
            <slot name="news-quote-image" />
          </template>
          <template v-if="config.excerpt" #excerpt>
            <slot name="news-quote-excerpt" />
          </template>
          <template v-if="config.author" #author>
            <slot name="news-quote-author" />
          </template>
          <template v-if="config.cite" #cite>
            <slot name="news-quote-cite" />
          </template>
        </quote-cmp>
      </template>
    </text-with-cmp>
    <text-with-cmp
      v-if="hasDownloadSection"
      class="pb-l download-section-class-identify"
    >
      <template v-if="config.file_description" #text>
        <slot name="news-file-description" />
      </template>
      <template v-if="config.topics && config.topics.length" #tags>
        <slot name="news-topics" />
      </template>
      <template #file>
        <media-download-cmp
          v-if="
            config.file_overtitle ||
            config.file_title ||
            config.file_text ||
            config.download_file
          "
        >
          <title-text-cmp>
            <template v-if="config.file_overtitle" #overtitle>
              <slot name="news-file-overtitle" />
            </template>
            <template v-if="config.file_title" #title>
              <slot name="news-file-title" />
            </template>
            <template v-if="config.file_text" #text>
              <slot name="news-file-text" />
            </template>
            <template v-if="config.download_file" #cta>
              <cta-button-cmp-extended
                class="btn-text"
                :data-url="config.download_file"
                :data-aria-label="download_label"
              >
                {{ download_label }}
                <template #after-icon>
                  <svg>
                    <use href="#icon-download" />
                  </svg>
                </template>
                <template #seo-link>
                  <slot name="news-file-seo-link" />
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-text-cmp>
        </media-download-cmp>
      </template>
    </text-with-cmp>
    <div class="spacer" />
    <section
      v-if="config.related_articles && config.related_articles.length"
      class="bg-color-blue-10 py-l"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <title-separator-cmp class="related-separator">
              <template #title>
                <h3>
                  {{
                    config.category.news_and_media_type === 'news'
                      ? detail_related_news_label
                      : detail_related_events_label
                  }}
                </h3>
              </template>
              <template #cta>
                <cta-button-cmp-extended
                  class="btn-text"
                  :data-url="
                    config.category.news_and_media_type === 'news'
                      ? detail_related_news_url
                      : detail_related_events_url
                  "
                >
                  {{
                    config.category.news_and_media_type === 'news'
                      ? detail_explore_all_news_label
                      : detail_explore_all_events_label
                  }}
                  <template #after-icon>
                    <svg>
                      <use href="#icon-arrow-right" />
                    </svg>
                  </template>
                  <template #seo-link>
                    <slot name="news-related-seo-link" />
                  </template>
                </cta-button-cmp-extended>
              </template>
            </title-separator-cmp>
            <div class="row">
              <div
                v-for="(related, i) in config.related_articles"
                :key="`related-${i}-${_uid}`"
                :class="[
                  'col-12',
                  'col-md-6',
                  'col-lg-4',
                  'related-article',
                  { first: i === 0 },
                ]"
              >
                <card-cmp :data-href="related.href" class="normal">
                  <template #seo-link>
                    <slot :name="`news-related-seo-link-${i + 1}`" />
                  </template>
                  <template #image>
                    <slot :name="`news-related-image-${i + 1}`" />
                  </template>
                  <template v-if="related.topics.length" #tags>
                    <slot :name="`news-related-tags-${i + 1}`" />
                  </template>
                  <template v-if="related.category" #category>
                    <slot :name="`news-related-category-${i + 1}`" />
                  </template>
                  <template #title>
                    <slot :name="`news-related-title-${i + 1}`" />
                  </template>
                  <template v-if="related.publication_start_iso" #date>
                    <time :datetime="related.publication_start_iso">
                      <beautiful-date
                        :data-datetime="related.publication_start_iso"
                        :data-locale="currentLang.language_code"
                        :data-format="$store.state.config.date_format"
                      >
                        {{ related.publication_start }}
                      </beautiful-date>
                    </time>
                  </template>
                  <template #cta>
                    <cta-button-cmp-extended class="btn-text" data-tag="button">
                      {{ news_and_media_read_more_label }}
                      <template #after-icon>
                        <svg>
                          <use href="#icon-arrow-right" />
                        </svg>
                      </template>
                    </cta-button-cmp-extended>
                  </template>
                </card-cmp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { debug } from '@/helpers/utils';
import { mapState } from 'vuex';
import { currentLang } from '@/helpers/utils';

export default {
  name: 'NewsAndMediaDetailPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType };
  },
  data() {
    return {
      isHoveringZoomIcon: false,
    };
  },
  computed: {
    currentLang() {
      return currentLang(this.$store.state.config.ordered_languages);
    },
    computedDisabled() {
      try {
        return this.config.slides.length <= 1 ? 0 : null;
      } catch (e) {
        return null;
      }
    },
    socials() {
      return [
        {
          slug: 'whatsapp',
          social: 'whatsapp',
          is_share: true,
          path: window.location.href,
          link: null,
          target: null,
          rel: null,
          title_share: '',
          subject_email: '',
          content_email: '',
          content_whatsapp: window.location.href,
          shareSmcEnabled: false,
          shareSmcClickForced: false,
          share_smc_click_event: '',
          share_smc_product_code: '',
          share_smc_highlighted_content: '',
        },
        {
          slug: 'email',
          social: 'email',
          is_share: true,
          path: window.location.href,
          link: null,
          target: null,
          rel: null,
          title_share: '',
          subject_email: `${this.config.category.title} - ${this.config.title}`,
          content_email: `${this.config.category.title} - ${this.config.title}`,
          content_whatsapp: '',
          shareSmcEnabled: false,
          shareSmcClickForced: false,
          share_smc_click_event: '',
          share_smc_product_code: '',
          share_smc_highlighted_content: '',
        },
        {
          slug: 'twitter',
          social: 'twitter',
          is_share: true,
          path: window.location.href,
          link: null,
          target: null,
          rel: null,
          title_share: '',
          subject_email: '',
          content_email: '',
          shareSmcEnabled: false,
          shareSmcClickForced: false,
          share_smc_click_event: '',
          share_smc_product_code: '',
          share_smc_highlighted_content: '',
        },
        {
          slug: 'print',
          social: 'print',
          is_share: true,
          path: window.location.href,
          link: null,
          target: null,
          rel: null,
          title_share: '',
          subject_email: '',
          content_email: '',
          shareSmcEnabled: false,
          shareSmcClickForced: false,
          share_smc_click_event: '',
          share_smc_product_code: '',
          share_smc_highlighted_content: '',
        },
      ];
    },
    ...mapState('config', ['date_format', 'download_label']),
    ...mapState('newsAndMedia', [
      'detail_explore_all_events_label',
      'detail_explore_all_news_label',
      'detail_related_events_label',
      'detail_related_events_url',
      'detail_related_news_label',
      'detail_related_news_url',
      'detail_time_label',
      'detail_when_event_label',
      'detail_where_event_label',
      'has_small_cover',
      'news_and_media_read_more_label',
    ]),
    config() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    hasEventSection() {
      return (
        this.config.start_date &&
        this.config.time_start &&
        this.config.time_end &&
        this.config.location &&
        this.config.register_link &&
        this.config.register_link_label
      );
    },
    eventTimespan() {
      let event_timespan_label = this.detail_time_label.replace(
        '§start§',
        this.config.time_start
      );
      event_timespan_label = event_timespan_label.replace(
        '§end§',
        this.config.time_end
      );

      return event_timespan_label;
    },
    hasMediaPlayerSection() {
      return (
        this.config.video_description ||
        this.config.video_caption ||
        this.config.video_link_code
      );
    },
    hasCarouselSection() {
      return (
        this.config.image_description ||
        (this.config.slides && this.config.slides.length)
      );
    },
    hasQuoteSection() {
      return (
        this.config.quote_text ||
        this.config.excerpt ||
        this.config.author ||
        this.config.cite
      );
    },
    hasDownloadSection() {
      return (
        this.config.download_file ||
        this.config.file_description ||
        this.config.file_overtitle ||
        this.config.file_text ||
        this.config.file_title ||
        (this.config.topics && this.config.topics.length)
      );
    },
  },
  methods: {
    trackOnGa4(social) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'social_share',
        'data-name-product': this.config.title,
        'data-name-method': social.social,
        'data-content-type': 'news',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.NewsAndMediaDetailPage {
  .HeroCmp {
    padding-bottom: 80px;
  }

  .event-info-section {
    padding-bottom: 80px;
  }

  .related-separator {
    padding-bottom: 40px;

    @include media-breakpoint-up(md) {
      padding-bottom: 32px;
    }

    ::v-deep .title-wrapper {
      @include override-responsive-typography-properties(
        $font-secondary,
        n-heading-3,
        --title-separator-title-wrapper
      );
    }
  }

  .related-article {
    @include media-breakpoint-down(md) {
      &:not(.first) {
        padding-top: 40px;
      }
    }
  }

  .spacer {
    padding: 40px 0 0;
  }

  .wysiwyg {
    @include override-responsive-typography-properties(
      $font-secondary,
      n-heading-1,
      --wysiwyg-h1
    );
    @include override-responsive-typography-properties(
      $font-secondary,
      n-heading-2,
      --wysiwyg-h2
    );
    @include override-responsive-typography-properties(
      $font-secondary,
      n-heading-3,
      --wysiwyg-h3
    );
    @include override-responsive-typography-properties(
      $font-primary,
      a-heading,
      --wysiwyg-h4
    );
    @include override-responsive-typography-properties(
      $font-primary,
      a-paragraph-1,
      --wysiwyg-h5
    );
    @include override-responsive-typography-properties(
      $font-primary,
      a-paragraph-2,
      --wysiwyg-h6
    );
    @include override-responsive-typography-properties(
      $font-primary,
      a-paragraph-3,
      --wysiwyg-paragraph
    );
    --wysiwyg-a-color: #{$color-blue};
    --wysiwyg-spacing-bottom: 32px;
    --wysiwyg-table-border-color: #{$color-grey-900-50};
    --wysiwyg-pre-bg-color: #{$color-red};
    --wysiwyg-mark-bg-color: #{$color-grey-900-50};

    /* Reset to browser default */
    ::v-deep * {
      all: revert;
      box-sizing: border-box;
    }

    ::v-deep h1 {
      font-family: $font-primary;
      font-size: var(--wysiwyg-h1-font-size, $base-fontsize);
      line-height: var(--wysiwyg-h1-line-height, inherit);
      font-weight: $fh-regular;
    }

    ::v-deep h2 {
      font-family: $font-primary;
      font-size: var(--wysiwyg-h2-font-size, $base-fontsize);
      line-height: var(--wysiwyg-h2-line-height, inherit);
      font-weight: $fh-regular;
    }

    ::v-deep h3 {
      font-family: $font-primary;
      font-size: var(--wysiwyg-h3-font-size, $base-fontsize);
      line-height: var(--wysiwyg-h3-line-height, inherit);
      font-weight: $fh-regular;
    }

    ::v-deep h4 {
      font-family: $font-primary;
      font-size: var(--wysiwyg-h4-font-size, $base-fontsize);
      line-height: var(--wysiwyg-h4-line-height, inherit);
      font-weight: $fh-regular;
    }

    ::v-deep h5 {
      font-family: $font-primary;
      font-size: var(--wysiwyg-h5-font-size, $base-fontsize);
      line-height: var(--wysiwyg-h5-line-height, inherit);
      font-weight: $fh-regular;
    }

    ::v-deep h6 {
      font-family: $font-primary;
      font-size: var(--wysiwyg-h6-font-size, $base-fontsize);
      line-height: var(--wysiwyg-h6-line-height, inherit);
      font-weight: $fh-regular;
    }

    ::v-deep a,
    ::v-deep ul,
    ::v-deep ol,
    ::v-deep dl,
    ::v-deep table,
    ::v-deep blockquote,
    ::v-deep address,
    ::v-deep pre,
    ::v-deep figcaption,
    ::v-deep label,
    ::v-deep span,
    ::v-deep p {
      font-family: $font-primary;
      font-size: var(--wysiwyg-paragraph-font-size, $base-fontsize);
      line-height: var(--wysiwyg-paragraph-line-height, inherit);
      font-weight: $fh-regular;
    }

    ::v-deep a {
      color: var(--wysiwyg-a-color, dodgerblue);
    }

    ::v-deep table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      margin: 0 0 var(--wysiwyg-spacing-bottom);

      th,
      td {
        border: var(--wysiwyg-table-border-width, 1px)
          var(--wysiwyg-table-border-style, solid)
          var(--wysiwyg-table-border-color, black);
        padding: var(--wysiwyg-table-cell-padding, 5px 10px);
      }

      caption,
      th,
      td {
        text-align: left;
      }
    }

    ::v-deep pre {
      width: auto;
      max-height: var(--wysiwyg-pre-max-height, 600px);
      margin: 0 0 var(--wysiwyg-spacing-bottom);
      padding: var(--wysiwyg-pre-padding, 0.5em 2.5em);
      background-color: var(--wysiwyg-pre-bg-color, navy);
      color: var(--wysiwyg-pre-color, white);
      overflow: auto;
    }

    ::v-deep code {
      white-space: pre-wrap;
    }

    ::v-deep pre code {
      white-space: inherit;
      padding: 0;
    }

    ::v-deep h1,
    ::v-deep h2,
    ::v-deep h3,
    ::v-deep h4,
    ::v-deep h5,
    ::v-deep h6,
    ::v-deep ul,
    ::v-deep ol,
    ::v-deep dl,
    ::v-deep p {
      margin: 0;
      padding-bottom: var(--wysiwyg-spacing-bottom);
    }

    ::v-deep ul ul,
    ::v-deep ol ul,
    ::v-deep ul ol,
    ::v-deep ol ol {
      padding-bottom: 0;
    }

    ::v-deep figure,
    ::v-deep blockquote {
      margin-block: 0;
      padding-bottom: var(--wysiwyg-spacing-bottom);

      p {
        padding-bottom: 0;
      }
    }

    ::v-deep mark {
      background-color: var(--wysiwyg-mark-bg-color, navy);
      color: var(--wysiwyg-mark-color, white);
    }

    ::v-deep img {
      max-width: 100%;
      height: auto;
    }

    ::v-deep & > :last-child {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
</style>
